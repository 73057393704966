exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-custompage-js": () => import("./../../../src/pages/custompage.js" /* webpackChunkName: "component---src-pages-custompage-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-detail-js": () => import("./../../../src/templates/blog-detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-blogs-js": () => import("./../../../src/templates/blogs.js" /* webpackChunkName: "component---src-templates-blogs-js" */),
  "component---src-templates-categorypages-js": () => import("./../../../src/templates/categorypages.js" /* webpackChunkName: "component---src-templates-categorypages-js" */),
  "component---src-templates-cobrand-detail-js": () => import("./../../../src/templates/cobrand-detail.js" /* webpackChunkName: "component---src-templates-cobrand-detail-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contact-us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-department-detail-js": () => import("./../../../src/templates/department-detail.js" /* webpackChunkName: "component---src-templates-department-detail-js" */),
  "component---src-templates-departments-js": () => import("./../../../src/templates/departments.js" /* webpackChunkName: "component---src-templates-departments-js" */),
  "component---src-templates-diabeter-doctor-detail-js": () => import("./../../../src/templates/diabeter-doctor-detail.js" /* webpackChunkName: "component---src-templates-diabeter-doctor-detail-js" */),
  "component---src-templates-doctor-detail-js": () => import("./../../../src/templates/doctor-detail.js" /* webpackChunkName: "component---src-templates-doctor-detail-js" */),
  "component---src-templates-doctors-js": () => import("./../../../src/templates/doctors.js" /* webpackChunkName: "component---src-templates-doctors-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-nabdaservice-js": () => import("./../../../src/templates/nabdaservice.js" /* webpackChunkName: "component---src-templates-nabdaservice-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-procedure-detail-js": () => import("./../../../src/templates/procedure-detail.js" /* webpackChunkName: "component---src-templates-procedure-detail-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-treatments-js": () => import("./../../../src/templates/treatments.js" /* webpackChunkName: "component---src-templates-treatments-js" */)
}

